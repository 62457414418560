import "./style.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { useGeographic } from "ol/proj";
import { Point } from "ol/geom";
import Feature from "ol/Feature";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { fromLonLat } from "ol/proj";
import Icon from "ol/style/Icon";
import Text from "ol/style/Text";

useGeographic();

const place = [9.1659159, 45.4921177];

const point = new Point(place);

const p1 = new Feature({
    geometry: new Point(place),
    label: " Netizens ",
});

const map = new Map({
    target: "map",
    layers: [
        new TileLayer({
            source: new OSM(),
        }),
        new VectorLayer({
            source: new VectorSource({
                features: [p1],
            }),
            style: new Style({
                image: new Icon({
                    src: "https://openlayers.org/en/latest/examples/data/icon.png",
                }),
                text: new Text({
                    font: "20px sans-serif",
                    text: p1.get("label"),
                    placement: "line",
                    fill: new Fill({
                        color: "green",
                    }),
                    offsetY: -45,
                    backgroundFill: new Fill({
                        color: "rgba(255, 255, 255, 0.8)",
                    }),
                }),
            }),
        }),
    ],
    view: new View({
        center: place,
        zoom: 16,
    }),
});
